import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import axios from 'axios'
import validator from 'validator'

import { Modal, Button } from 'antd';
import "../css/rahul-styles.css"

const PopupForm = (props) => {

  const {time, programType, country, sourcePage, brochureLink,  handleTimeChange} = props 

 


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')

  
  const [policy, setPolicy] = useState(false) 
  const [formError, setFormError] = useState({})
  const errors = {}

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  

  const handleChange = (e) => {
    if(e.target.name === 'firstName') {
      setFirstName(e.target.value)
    } else if (e.target.name === 'lastName') {
      setLastName(e.target.value)
    } else if (e.target.name === 'email') {
      setEmail(e.target.value)
    } else if (e.target.name === 'number') {
      setNumber(e.target.value)
    }
  }

  const handlePolicy = (e) => {
    const policyInput = e.target.checked 
    setPolicy(policyInput) 
  }

  const runValidations = () => {
    if(firstName.trim().length === 0) {
      errors.firstName = "Required"
    } 
   
    
    if (email.trim().length === 0) {
      errors.email = "Required"
    } else if (!validator.isEmail(email)) {
      errors.email = "Invalid E-Mail Format"
    }
    if (number.trim().length === 0) {
      errors.number = "Required"
    } else if (number.trim().length < 10) {
      errors.number = "Atleast 10 digits required"
    } 
 
    
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    runValidations()


    if(Object.keys(errors).length === 0) {
      setFormError({})
      const formData = {
        firstName: firstName,
        email: email,
        number: number,

        policy: policy,

      }


      setFirstName('')
   
      setEmail('')
      setNumber('')

      setPolicy(false)

    
      

      handleCancel()

      axios.post('https://api.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r818cc16ada0b011c2981128aabe70284&secretKey=65a4a7aea451fbd17be5958bff3d23abe3bcb92f', [
        {
          "Attribute": "FirstName",
          "Value": firstName
        },
    
        {
          "Attribute": "EmailAddress",
          "Value": email
        },
        {
          "Attribute": "Phone",
          "Value": number
        },
        {
          "Attribute": "SearchBy",
          "Value": "Phone"
        },
        {
          "Attribute": "mx_Student_program_level",
          "Value": programType
        },
        {
          "Attribute": "mx_Target_Destination",
          "Value": country
        },
    
        {
          "Attribute": "ProspectID",
          "Value": window ? window.MXCProspectId ? window.MXCProspectId : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx" : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx"
        },
   
        {
          "Attribute": "mx_Read_TWG_Privacy_Policy",
          "Value": policy 
        },
        {
          "Attribute": "Source",
          "Value": "Website Enquiry"
        },
        {
          "Attribute": "mx_Lead_Captured_at",
          "Value": sourcePage
        }
      ])
      .then(response => {
   
        if(response.data.Status == "Success") {
          swal("Done!", "Brochure Downloaded", "success");
          document.getElementById('actualDownloadButton').click();
        }else {
         
            swal("Sorry!", "There is some problem with your submission.", "failure");
         
        }

      })
      .catch(err => {
        alert(err.message)
   
      })

    } else {
      setFormError(errors)
    }
  }


  return (
    <>
 
      <a data-animation="animated zoomInUp" class="btn btn-theme effect btn-md modal-popup center-button-110" onClick={showModal}  href="#download_brochure">Download Brochure</a>
      <a data-animation="animated zoomInUp" id="actualDownloadButton" download="ProgramBrochure.PDF"  style={{display: 'none'}} href={"https://assets.theworldgrad.com/gatsby-assets/downloadBrochure.php?filename="+encodeURI(brochureLink)}>Download Brochure</a>

      <Modal className="antd-modal-main antd-modal-download-form" footer="null" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={400} >
        
      <div class="container newStyle">
                                <div class="row justify-content-center">
                                    <div class="col-12  bg-white modal-popup-main">
                                        <div class="row">
                                        <div class="col-12 bg-white modal-popup-main popup-form-apt-new p-0">
                                                <button title="Close (Esc)" type="button" class="mfp-close">×</button>
                                                <div class="get-in-touch-header padding-4-rem-lr position-relative">
                <h4 class="font-weight-500 d-block margin-10px-bottom">Please provide the following information <span className='nowrap'>to download</span> the brochure</h4>
              </div>
                                               
              <div className='col-12 margin-20px-top'>
                                                <div class="row">
                                                    
                                                    <div class="col-md-6 plr-7 form-field">
      
                    <input 
                      name='firstName'
                      value={firstName}
                      onChange={handleChange}
                      type="text" 
                      className="small-input" placeholder="Type Here" 
                    />
                    <label className="form-label form-label-popup ">NAME* {formError.firstName &&  <span className="text-danger error-message-text"> {formError.firstName} </span> }</label>
      </div>
                                                    
                                                    <div class="col-md-6 plr-7 form-field">
      
                    <input
                      name='email'
                      value={email}
                      onChange={handleChange}
                      type="email" 
                      className="small-input" 
                      placeholder="Type Here" 
                    />
                    <label className="form-label form-label-popup">E-MAIL* {formError.email && <span className="text-danger error-message-text"> {formError.email} </span>}</label>
      </div>
                                                    
                                                    <div class="col-md-12 plr-7 form-field">
      
                    <input 
                      name='number'
                      value={number}
                      onChange={handleChange}
                      type="number" 
                      className="small-input" 
                      placeholder="+91" 
                    />
                    <label className="form-label form-label-popup">PHONE NUMBER* {formError.number && <span className="text-danger error-message-text"> {formError.number} </span>}</label>
      </div>
                                                </div>
                                                
                                             
                                                
                                                <div class="row">
                                                    <div class="col-12 plr-7">
      <input 
                        checked={policy}
                        onChange={handlePolicy}
                        className="d-none" 
                        type="checkbox" 
      id="newsletter-off"
                      />
      <label for="newsletter-off" class="text-very-small"><span></span>I have read and understood <a href="/privacypolicy">The WorldGrad Privacy Policy </a></label>

                                                    </div>
                                                </div>
                                                
                                                <div class="row justify-content-center">
                <div class="col-md-12 button-body plr-7">
                      <button 
                        type="button" 
                        className="btn btn-theme btn-fast-yellow effect btn-md"
                        onClick={handleSubmit}
                        disabled={!policy}
                      >SUBMIT</button>
                    </div></div>
                    </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
      
      
      </Modal>
    </>
  )
}

export default PopupForm
